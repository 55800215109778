
import { createContext, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { includes } from "lodash"; 

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import MENU_ROUTES from "./config/menuRoutes";

import Website from './Website';
import Registraion from './Registraion';
import AcountPersonal from './AcountPersonal';
import AcountPayment from './AcountPayment';
import About from './About';
import Login from "./pages/Login";
import ResetPasswordForm from "./pages/ResetpasswordForm";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";
import Table from "./components/Table";
import SimpleTable from "./components/SimpleTable";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Maintenance from "./pages/Maintenance";
import Construction from "./pages/Construction";
import SimpleForm from "./pages/SimpleForm";
import Toasty from "./components/Toasty";
import Popupbox from "./pages/Popupbox";
import FilePicker from "./components/FilePicker";
import UpdateProfile from "./pages/Company/UpdateProfile";

import AccountDetail from './AccountDetail';
import CompanyRepresentative from './CompanyRepresentative';
import ContactDetail from './ContactDetail';

// Admin Pages 
import Members from "./pages/admin/Members";
import Users from "./pages/admin/Users";

import AccountNotification from "./AccountNotification";
import TopMenu from "./components/TopMenu";
import Updateprofile from "./pages/Compney/Updateprofile";
import OrexSidebarsProviders from "./components/OrexSidebarsProviders"

import './App.css';
import './developer.css';

export let property = createContext("")

function App(props) {

  const [account, setaccount] = useState("");
  const [compney, setcompney] = useState("");
  const [compnytext, setcompnytext] = useState("");
  const [RegiIcon, setRegiIcon] = useState("");
  const [profileName, setProfileName] = useState();

  const [visibleSidebar, setSidebar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    if(includes(MENU_ROUTES,location.pathname)) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  }, []);

  return (

    <div>

      {visibleSidebar && <OrexSidebarsProviders />}

      <property.Provider value={{ account, setaccount, compney, setcompney, compnytext, setcompnytext, RegiIcon, setRegiIcon, profileName,setProfileName }}>

        <Routes>
          <Route path='/' element={<Website />}></Route>
          <Route path='/registration' element={<AccountDetail />}></Route>
          <Route path='/personal' element={<CompanyRepresentative />}></Route>
          <Route path='/payment' element={<AcountPayment />}></Route>
          <Route path='/account' element={<Registraion />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/resetpassword" element={<ResetPasswordForm />}></Route>
          <Route path="/forgotpassword" element={<ForgotPasswordForm />}></Route>
          <Route exact path='/404' element={<Error404 />}></Route>
          <Route exact path='/500' element={<Error500 />}></Route>
          <Route exact path='/maintenance' element={<Maintenance />}></Route>
          <Route exact path='/construction' element={<Construction />}></Route>
          <Route path="/table" element={<Table />}></Route>
          <Route path="/table02" element={<SimpleTable />}></Route>
          <Route exact path='/form' element={<SimpleForm />}></Route>
          <Route exact path='/toasty' element={<Toasty />}></Route>
          <Route exact path='/popup' element={<Popupbox />}></Route>
          <Route path='/account-detail' element={<AccountDetail />}></Route>
          <Route path='/company-representative' element={<CompanyRepresentative />}></Route>
          <Route path='/contact-detail' element={<ContactDetail />}></Route>
          <Route exact path='/filepicker' element={<FilePicker />}></Route>
          { /*<Route exact path='/admin/members' element={<Members />} ></Route>
          <Route exact path='/admin/users' element={<Users />} ></Route>      **/}     
          <Route exact path='/sidebar' element={<OrexSidebarsProviders />}></Route>
          { /** <Route path='/company/update-profile' element={<UpdateProfile />}></Route> */} 
          <Route exact path='/members' element={<Members />} ></Route>
          <Route exact path='/users' element={<Users />} ></Route>  
          <Route exact path='/company-update-profile' element={<UpdateProfile />} ></Route> 


        </Routes>

        {/* <OrexSidebarsProviders /> */}

      </property.Provider>  

      

    </div>

  );
}

export default App;
